import React from 'react';
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent'
import { Image } from '../../Image'
import './LinkList.scss';

export const LinkList = props => {
  const { links, title, introContent, listView, siteUrl, moreLink } = props;
  return (
    <section className="links-list">
      <div className="wrap">
        <div className="intro">
          {title && <h3 className="section-title" dangerouslySetInnerHTML={{ __html: (title) }}/>}
          {introContent && <RenderContent className="intro-text" content={introContent}/>}
        </div>
        <div className={`links${listView ? ' list' : ' tiles'}`}>
          {links && links.map((link, index) => (
            link.link && link.link.url &&
              <GatsbyLink className="item" key={index} to={link.link.url.replace(process.env.GATSBY_WP_ADDRESS, siteUrl)} target={link.link.target}>
              <div className="inner">
                {link.link.title && <button className="action">{link.link.title}</button>}
              </div>
            </GatsbyLink>
          ))}
        </div>
        {moreLink &&
          <div className="more">
            <GatsbyLink className="action" to={moreLink.url} target={moreLink.target}>{moreLink.title}</GatsbyLink>
          </div>
        }
      </div>
    </section>
  );
};

export default LinkList;
