import React from 'react';
import loadable from '@loadable/component'

export const PostFeed = (props) => {
  if (props.contentType === 'post') {
    const RelatedPosts = loadable(props => import(`../../Related/RelatedPosts.js`))
    return <RelatedPosts {...props} />
  }
  return null
}

export default PostFeed;
