import React, { Component } from 'react';
import RenderContent from '../../../components/RenderContent';
import './Tabs.scss';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { decodeEntities } from '../../../utils/helpers';

const isClient = typeof window !== 'undefined';

class ThisTabs extends Component {
  state = { openNavKey: 0 };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: 0 });
    setTimeout(function() { scrollTo(`#tabTop-${openNavKey}`); }, 400);
    return this.setState({ openNavKey });
  }

  pad = (num, size) => {
    var s = "000000000" + num;
    return s.substr(s.length-size);
  }

  render() {
    const { tabs, title } = this.props;
    const { openNavKey } = this.state;
    if (!tabs) return null
    return (
      <section className="tabs">
        <div className="wrap">
          <div className="inner">
            <div className="navigation">
              {title && <h3 className="section-title" dangerouslySetInnerHTML={{ __html: decodeEntities(title) }} />}
              {tabs && tabs.map((tab, index) =>
                <div className={`link${openNavKey === index ? ' active' : ''}`} key={index} onClick={event => this.toggleSubNav(event, index)}>
                  <span>{tab.tabTitle}</span>
                </div>
              )}
            </div>
            <div className="tab-container">
              {tabs && tabs.map((tab, index) =>
                <div key={index} className={`tab${openNavKey === index ? ' open' : ' closed'}`}>
                  <div id={`tabTop-${index}`} className="anchour"/>
                  <div className="mobile-title" onClick={event => this.toggleSubNav(event, index)}>
                    {tab.tabTitle}<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
                  </div >
                  <div className="content"><RenderContent content={`<h3 class="m-hide"><span class="bignum">${this.pad(index+1, 2)}</span>${tab.tabTitle}</h3>${tab.tabContent}`}/></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}


export const Tabs = (props) => {
  return (
    <ThisTabs {...props} />
  )
}

export default Tabs;
