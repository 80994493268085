import React from 'react';
import RenderContent from '../../RenderContent';
import { Image } from '../../Image'
import { SideNavigation } from '../../SideNavigation'
import './ChildNaivation.scss'

export const ChildNavigation = (props) => {
  const {title, content, pageContext} = props
  return (
    <section className={`child-navigation`}>
      <div className="wrap">
        <div className="inner">
          <div className="title" >
            <h3 dangerouslySetInnerHTML={{__html: title}} className="section-title" />
          </div>
          <div className="content">
            <RenderContent content={content}/>
            <SideNavigation pageContext={pageContext}/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChildNavigation;
