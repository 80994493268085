import React from 'react';
import { RenderShortcode } from '../../RenderShortcode';
import { Image } from '../../Image'
import './CustomSection.scss'

export const CustomSection = (props) => {
  const {title, content, image, bigNumber, sectionBackground, textBackground} = props
  return (
    <section className={`custom-section${sectionBackground ? ' section-background' : ' no-background'}${textBackground ? ' text-background' : ''}`}>
      <div className="background">
        <div className={`wrap${!title ? ' remove' : ''}`}>
          <div className="inner">
            {title &&
              <div className="title" >
                <h3 dangerouslySetInnerHTML={{__html: title}} className="section-title" />
                {bigNumber && <span className="big-number">{bigNumber}</span>}
              </div>
            }
            <div className="content">
              <RenderShortcode content={content}/>
            </div>
          </div>
        </div>
        {image && image.source_url &&
          <div className="image">
            <div className="aspect">
              <Image src={image}/>
            </div>
          </div>
        }
      </div>
    </section>
  )
}

export default CustomSection;
