import React from 'react';
import RenderContent from '../../../components/RenderContent';
import { Image } from '../../../components/Image';
import GatsbyLink from '../../../components/GatsbyLink';
import './FeatureList.scss';
import { decodeEntities } from '../../../utils/helpers';

export const FeatureList = (props) => {
  const { features, title, link } = props;
  return (
    <section className="features-list">
      <div className="wrap">
        <div className="inner">
          {title && <h3 dangerouslySetInnerHTML={{ __html: decodeEntities(title) }} />}
          <div className="features-container">
            {features && features.map((feature, index) =>
              <div key={index} className="feature">
                <div className="content">
                  {feature.image && <div className="image"><Image src={feature.image}/></div>}
                  <RenderContent content={`<h4 class="feature-title">${feature.title}</h4>${feature.content}`}/>
                </div>
              </div>
            )}
          </div>
          {link &&
            <div className="link-wrap">
              <GatsbyLink className="action" to={link.url}>{link.title}</GatsbyLink>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default FeatureList;
